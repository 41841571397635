import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-696f4e98"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chatDetailWrap" }
const _hoisted_2 = { class: "msgWrap" }
const _hoisted_3 = { class: "msgWrap" }
const _hoisted_4 = { style: {"background":"red","font-size":"30px"} }
const _hoisted_5 = { class: "sendMsgWrap" }
const _hoisted_6 = { class: "richMsgWrap" }
const _hoisted_7 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_Field = _resolveComponent("Field")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_MsgLeft = _resolveComponent("MsgLeft")!
  const _component_Tag = _resolveComponent("Tag")!
  const _component_Popup = _resolveComponent("Popup")!
  const _component_MsgRight = _resolveComponent("MsgRight")!
  const _component_GridItem = _resolveComponent("GridItem")!
  const _component_Grid = _resolveComponent("Grid")!
  const _component_Popover = _resolveComponent("Popover")!
  const _component_Uploader = _resolveComponent("Uploader")!
  const _component_Input = _resolveComponent("Input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NavBar, {
      title: _ctx.chat.fromId,
      "left-arrow": "",
      onClickLeft: _ctx.chat.toChat
    }, {
      right: _withCtx(() => [
        _createVNode(_component_Icon, {
          class: "icon",
          onClick: _ctx.chat.showPinedMessageList,
          size: "20",
          name: "smile-comment-o"
        }, null, 8, ["onClick"])
      ]),
      _: 1
    }, 8, ["title", "onClickLeft"]),
    _createVNode(_component_Dialog, {
      onConfirm: _ctx.chat.modifyMessage,
      show: _ctx.chat.isShowModifyModal,
      "onUpdate:show": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.chat.isShowModifyModal) = $event)),
      title: "编辑消息",
      "show-cancel-button": ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Field, {
          modelValue: _ctx.chat.modifiedText,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.chat.modifiedText) = $event)),
          placeholder: "请输入"
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["onConfirm", "show"]),
    _createVNode(_component_Popup, {
      show: _ctx.chat.isShowPinnedMessage,
      "onUpdate:show": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.chat.isShowPinnedMessage) = $event)),
      position: "bottom",
      style: { minHeight: '30%', maxHeight: '70%' }
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chat.pinInfo[`${_ctx.chat.chatType}${_ctx.chat.fromId}`]
            ?.messageList, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              style: {"text-align":"left"},
              key: item.message.id
            }, [
              _createVNode(_component_MsgLeft, {
                msg: item.message,
                timestamp: item.message.time,
                isPinned: true,
                onUnpinMessage: ($event: any) => (_ctx.chat.unpinMessage(item.message.id)),
                onPreviewCombineMsg: _ctx.chat.previewCombineMsg
              }, null, 8, ["msg", "timestamp", "onUnpinMessage", "onPreviewCombineMsg"]),
              _createVNode(_component_Tag, null, {
                default: _withCtx(() => [
                  _createTextVNode(" 置顶时间: " + _toDisplayString(item.pinTime) + " 操作人: " + _toDisplayString(item.operatorId), 1)
                ]),
                _: 2
              }, 1024)
            ]))
          }), 128))
        ])
      ]),
      _: 1
    }, 8, ["show"]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.chat.chatInfo[`${_ctx.chat.chatType}${_ctx.chat.fromId}`]?.messageList.length), 1),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chat.chatInfo[`${_ctx.chat.chatType}${_ctx.chat.fromId}`]
          ?.messageList, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          key: item.id
        }, [
          (item.from === _ctx.chat.currentUser || item.from === '')
            ? (_openBlock(), _createBlock(_component_MsgRight, {
                key: 0,
                msg: item,
                timestamp: item.time,
                isPinned: 
            _ctx.chat.pinMessageIdsMap[`${_ctx.chat.chatType}${_ctx.chat.fromId}`]?.includes(
              item.id
            )
          ,
                onPreviewImg: _ctx.chat.previewImage,
                onRecallMessage: ($event: any) => (_ctx.chat.revokeMsg(item.id)),
                onModifyMessage: ($event: any) => (_ctx.chat.showModifyModal(item.id)),
                onPreviewCombineMsg: _ctx.chat.previewCombineMsg,
                onPinMessage: ($event: any) => (_ctx.chat.pinMessage(item.id))
              }, null, 8, ["msg", "timestamp", "isPinned", "onPreviewImg", "onRecallMessage", "onModifyMessage", "onPreviewCombineMsg", "onPinMessage"]))
            : (_openBlock(), _createBlock(_component_MsgLeft, {
                key: 1,
                msg: item,
                timestamp: item.time,
                isPinned: 
            _ctx.chat.pinMessageIdsMap[`${_ctx.chat.chatType}${_ctx.chat.fromId}`]?.includes(
              item.id
            )
          ,
                onPreviewImg: _ctx.chat.previewImage,
                onPreviewCombineMsg: _ctx.chat.previewCombineMsg,
                onPinMessage: ($event: any) => (_ctx.chat.pinMessage(item.id))
              }, null, 8, ["msg", "timestamp", "isPinned", "onPreviewImg", "onPreviewCombineMsg", "onPinMessage"]))
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_Popover, {
          show: _ctx.chat.emojiShow,
          "onUpdate:show": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.chat.emojiShow) = $event)),
          placement: "top-start"
        }, {
          reference: _withCtx(() => [
            _createVNode(_component_Icon, {
              class: "icon",
              size: "20",
              name: "smile-o"
            })
          ]),
          default: _withCtx(() => [
            _createVNode(_component_Grid, {
              square: "",
              clickable: "",
              border: false,
              "column-num": "10",
              style: {"width":"240px","padding-bottom":"5px"}
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chat.emojiLs, (i) => {
                  return (_openBlock(), _createBlock(_component_GridItem, {
                    key: i,
                    onClick: ($event: any) => (_ctx.chat.sendEmoji(i.url))
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("img", {
                        class: "emoji",
                        src: i.url,
                        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.chat.selectEmoji && _ctx.chat.selectEmoji(...args)))
                      }, null, 8, _hoisted_7)
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["show"]),
        _createVNode(_component_Uploader, {
          "after-read": _ctx.chat.afterReadImg
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Icon, {
              class: "icon",
              size: "20",
              name: "photo-o"
            })
          ]),
          _: 1
        }, 8, ["after-read"]),
        _createVNode(_component_Uploader, {
          accept: "video/*",
          "after-read": _ctx.chat.afterReadVideo
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Icon, {
              class: "icon",
              size: "20",
              name: "video-o"
            })
          ]),
          _: 1
        }, 8, ["after-read"]),
        _createVNode(_component_Uploader, {
          "after-read": _ctx.chat.afterReadAttach,
          accept: "*"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Icon, {
              class: "icon",
              size: "20",
              name: "idcard"
            })
          ]),
          _: 1
        }, 8, ["after-read"]),
        _createVNode(_component_Icon, {
          class: "icon",
          size: "20",
          name: "balance-o",
          onClick: _ctx.chat.sendCustomMsg
        }, null, 8, ["onClick"]),
        _createVNode(_component_Icon, {
          class: "icon",
          size: "20",
          name: "replay",
          onClick: _ctx.chat.sendCmdMsg
        }, null, 8, ["onClick"]),
        _createVNode(_component_Icon, {
          class: "icon",
          size: "20",
          name: "share-o",
          onClick: _ctx.chat.sendCombineMsg
        }, null, 8, ["onClick"]),
        _createElementVNode("span", {
          onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.chat.sendAck && _ctx.chat.sendAck(...args)))
        }, "ack")
      ]),
      _createElementVNode("div", {
        class: "sendBtn",
        onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.chat.onSendClick && _ctx.chat.onSendClick(...args)))
      }, "发送"),
      _createVNode(_component_Input, {
        ref: "ipt",
        onSend: _ctx.chat.sendMsg
      }, null, 8, ["onSend"])
    ])
  ]))
}