
import { Options, Vue, setup } from "vue-class-component";
import { useRouter, Router, useRoute } from "vue-router";
import Input from "@/components/input.vue";
import { formatTime } from "@/utils";
// import WebIM from "easemob-websdk";
import {
  NavBar,
  Icon,
  Popover,
  Grid,
  GridItem,
  Uploader,
  ImagePreview,
  Toast,
  Dialog,
  Field,
  Popup,
  Tag
} from "vant";
import { useStore } from "vuex";
import { getCurrentInstance, ref, onMounted, onUnmounted } from "vue";
import { MSG_TYPE, CHAT_TYPE } from "@/const";
import MsgLeft from "@/components/messageLeft.vue";
import MsgRight from "@/components/messageRight.vue";
import emoji from "@/const/emojs";
import { scrollToBottom } from "@/utils";
import { deliverMsg, formatImFile, createMsg, recallMessage } from "@/utils/im";
import { EasemobChat } from "easemob-websdk/Easemob-chat";
import { AllState } from "@/store";

@Options({
  components: {
    NavBar,
    Input,
    Field,
    Icon,
    MsgLeft,
    MsgRight,
    Popover,
    Grid,
    GridItem,
    Uploader,
    Dialog: Dialog.Component,
    Popup,
    Tag
  }
})
export default class Contact extends Vue {
  chat = setup(() => {
    const router: Router = useRouter();
    const route = useRoute();
    const store = useStore<AllState>();
    const conn = store.state.IM.connect;
    const instance = getCurrentInstance();
    const fromId = route.params.fromId as string;
    const chatType = route.params.chatType as CHAT_TYPE;
    const emojiShow = ref(false);
    const isShowModifyModal = ref<boolean | string>(false);
    const chatId = `${chatType}${fromId}`;
    const modifiedText = ref("");
    const isShowPinnedMessage = ref(false);
    let pinedMessages = ref<any>([]);
    const previewImage = (url: string) => {
      // TODO: 发送接收消息时push图片url,不要每次都获取
      const imgMsgList = store.state.IM.chat[chatId]?.messageList
        .filter((item: EasemobChat.MessageBody) => {
          return item.type === MSG_TYPE.img;
        })
        .map((imgItem: any) => {
          return imgItem.url || imgItem.body.url;
        });

      ImagePreview({
        images: imgMsgList,
        startPosition: imgMsgList.findIndex((imgUrl: string) => {
          return imgUrl === url;
        })
      });
    };

    const previewCombineMsg = (msg: any) => {
      const { url, secret } = msg;
      console.log(url, secret);
      conn
        .downloadAndParseCombineMessage({
          url,
          secret
        })
        .then((res: any) => {
          console.log(res);
        });
    };

    const emojiLs = Object.entries(emoji.obj).map((item) => {
      return {
        name: item[0],
        url: `/emoji/${item[1]}`
      };
    });

    const selectEmoji = () => {
      emojiShow.value = false;
    };

    const toChat = () => {
      router.go(-1);
    };

    // 获取历史消息
    const getHistoryMsg = () => {
      const options = {
        queue: fromId.toLowerCase(),
        isGroup: chatType !== CHAT_TYPE.singleChat,
        count: 10,
        format: true
      };
      conn.fetchHistoryMessages(options).then((res) => {
        console.log(res, "getHistoryRes");
        res.forEach((item: any) => {
          store.commit("IM/pushMessage", {
            fromId: chatId,
            message: item
          });
        });
      });
    };

    // 收到消息滚动到底部
    conn.addEventHandler("MESSAGE_SCROLL", {
      onTextMessage: () => {
        setTimeout(() => {
          scrollToBottom(document.getElementById("msgWrap"));
        }, 200);
      }
    });

    const onSendClick = () => {
      const ipt: any = instance?.refs.ipt;
      if (ipt.ipt.txt) {
        sendMsg(ipt.ipt.txt);
      } else {
        Toast("不能发送空消息");
      }
    };

    // 发送文本和表情消息
    const sendMsg = (txt: string) => {
      let msg: any = createMsg({
        chatType: chatType,
        type: MSG_TYPE.txt,
        to: fromId,
        msg: txt,
        priority: "high",
        ext: {
          extra: undefined
          // em_apns_ext: {
          //   em_push_title: "custom title",
          //   em_push_content: "custom content"
          // } // iOS推送消息扩展
        }
      });

      // var id = conn.getUniqueId();
      // var msg = new WebIM.message("txt", id);
      // //@ts-ignore
      // msg.set({
      //   msg: txt,
      //   to: fromId,
      //   ext: {
      //     time: "123",
      //     xyz: undefined
      //   }
      // });

      deliverMsg(msg).then((res) => {
        console.log("发送成功", msg, res);
        store.commit("IM/pushMessage", {
          fromId: chatId,
          //@ts-ignore
          message: res?.message || msg
        });
        const ipt: any = instance?.refs.ipt;
        ipt.ipt.clear();
        scrollToBottom(document.getElementById("msgWrap"));
      });
    };

    // 点击表情
    const sendEmoji = (emoji: string) => {
      emojiShow.value = false;
      const ipt: any = instance?.refs.ipt;
      const emojiStr = `<img class="emojiItem" src="${emoji}"/>`;
      ipt.ipt.mergeTxt(emojiStr);
    };

    const afterReadImg = (file: any) => {
      // 直接发送图片URL(用户自行上传图片到自己的服务器)
      // Web端需要在 WebIMConfig.js中 设置 useOwnUploadFun: true
      // const imgMsg:any = createMsg({
      //   chatType: chatType,
      //   type: MSG_TYPE.img,
      //   url: "https://www.easemob.com/statics/common/images/logo.png?20211109",
      //   to: fromId,
      //   width: 20,
      //   height: 20,
      // });

      // conn.uploadGroupSharedFile({
      //   groupId: fromId,
      //   file: formatImFile(file.file) as any,
      //   onFileUploadCanceled: () => {
      //     console.log('canceled')
      //   },
      //   onFileUploadComplete: (res) => {
      //        console.log('onFileUploadComplete', res)
      //   },
      //   onFileUploadError: () => {},
      //   onFileUploadProgress: () => {}
      // });

      // conn.uploadGroupSharedFile({
      //   groupId: fromId,
      //   file: formatImFile(file.file) as any,
      // })

      const imgMsg: any = createMsg({
        chatType: chatType,
        type: MSG_TYPE.img,
        to: fromId,
        file: formatImFile(file.file) as any,
        width: 200,
        height: 200,
        thumbnailHeight: 20,
        thumbnailWidth: 20,
        onFileUploadError: function (e) {
          // 消息上传失败
          console.log("onFileUploadError", e);
        },
        onFileUploadProgress: function (progress) {
          // 上传进度的回调
          console.log(progress, "progressssss");
        },
        onFileUploadComplete: function () {
          // 消息上传成功
          console.log("onFileUploadComplete");
        }
      });

      console.log(imgMsg, "imgMsgimgMsg");

      // 发送图片消息
      deliverMsg(imgMsg).then((res) => {
        console.log(imgMsg, "imgMsg");
        store.commit("IM/pushMessage", {
          fromId: chatId,
          message: imgMsg
        });
        setTimeout(() => {
          scrollToBottom(document.getElementById("msgWrap"));
        }, 200);
        console.log(res, "发送图片消息成功");
      });
    };

    const afterReadAttach = (file: any) => {
      // 发送附件消息
      const attachMsg: any = createMsg({
        chatType: chatType,
        type: MSG_TYPE.file,
        to: fromId,
        filename: file.file.name,
        file: formatImFile(file.file) as any,
        onFileUploadError: function () {
          // 消息上传失败
          console.log("onFileUploadError");
        },
        onFileUploadProgress: function (progress) {
          // 上传进度的回调
          console.log(progress);
        },
        onFileUploadComplete: function () {
          // 消息上传成功
          console.log("onFileUploadComplete");
        }
      });

      deliverMsg(attachMsg).then((res) => {
        console.log(attachMsg, "attachMsg");
        store.commit("IM/pushMessage", {
          fromId: chatId,
          message: attachMsg
        });
        setTimeout(() => {
          scrollToBottom(document.getElementById("msgWrap"));
        }, 200);
        console.log(res, "发送附件消息成功");
      });
    };

    const afterReadVideo = (file: any) => {
      // 发送视频消息
      const videoMsg: any = createMsg({
        chatType: chatType,
        type: MSG_TYPE.video,
        to: fromId,
        filename: file.file.name,
        file: formatImFile(file.file) as any,
        onFileUploadError: function () {
          // 消息上传失败
          console.log("onFileUploadError");
        },
        onFileUploadProgress: function (e) {
          // 上传进度的回调
          console.log(e);
        },
        onFileUploadComplete: function (data) {
          // 消息上传成功
          console.log("onFileUploadComplete", data);
        }
      });

      deliverMsg(videoMsg).then((res) => {
        console.log(videoMsg, "attachMsg");
        store.commit("IM/pushMessage", {
          fromId: chatId,
          message: videoMsg
        });
        setTimeout(() => {
          scrollToBottom(document.getElementById("msgWrap"));
        }, 200);
        console.log(res, "发送视频消息成功");
      });
    };

    // 发送自定义消息
    const sendCustomMsg = () => {
      const customMsg: any = createMsg({
        chatType: chatType,
        type: MSG_TYPE.custom,
        to: fromId,
        customEvent: "RED_PACKAGE",
        customExts: {
          amount: 1000
        }
      });

      deliverMsg(customMsg).then((res) => {
        store.commit("IM/pushMessage", {
          fromId: chatId,
          message: customMsg
        });
        setTimeout(() => {
          scrollToBottom(document.getElementById("msgWrap"));
        }, 200);
        console.log(res, "发送自定义消息成功");
      });
    };

    // 发送命令消息
    const sendCmdMsg = () => {
      const cmdMsg: any = createMsg({
        chatType: chatType,
        type: MSG_TYPE.cmd,
        to: fromId,
        action: "refresh"
      });

      console.log(cmdMsg, "cmdMsg");

      deliverMsg(cmdMsg).then((res) => {
        store.commit("IM/pushMessage", {
          fromId: chatId,
          message: cmdMsg
        });
        setTimeout(() => {
          scrollToBottom(document.getElementById("msgWrap"));
        }, 200);
        console.log(res, "发送命令消息成功");
      });
    };

    const revokeMsg = (id: string) => {
      const options = {
        mid: id,
        to: fromId,
        chatType: chatType,
        ext: JSON.stringify({
          chatType: chatType
        })
      };
      recallMessage(options).then((res) => {
        store.commit("IM/deleteMessage", { fromId: chatId, id });
        console.log(res, "撤回消息成功");
      });
    };

    const showModifyModal = (id: string) => {
      isShowModifyModal.value = id;
    };

    const showPinedMessageList = () => {
      isShowPinnedMessage.value = true;
      getPinnedMessages();
    };

    const modifyMessage = () => {
      let msg = createMsg({
        chatType: chatType,
        type: MSG_TYPE.txt,
        to: fromId,
        msg: modifiedText.value,
        ext: {
          x: 1
        }
      }) as EasemobChat.TextMsgBody;

      conn
        .modifyMessage({
          messageId: isShowModifyModal.value as string,
          modifiedMessage: msg
        })
        .then((res) => {
          console.log("modify msg success", res);
          store.commit("IM/updateMessage", {
            fromId: chatId,
            modifiedMsg: res.message
          });
        })
        .catch((e) => {
          console.log(e, "modify catch res");
        });

      isShowModifyModal.value = false;
      modifiedText.value = "";
    };

    const pinMessage = (id: string) => {
      conn
        .pinMessage({
          conversationId: fromId,
          conversationType: chatType as "groupChat" | "chatRoom",
          messageId: id
        })
        .then(() => {
          console.log("pinMessage Success");
        })
        .catch((e: any) => {
          console.log(e, "pinMessage failed");
        });
    };

    const unpinMessage = (id: string) => {
      conn
        .unpinMessage({
          conversationId: fromId,
          conversationType: chatType as "groupChat" | "chatRoom",
          messageId: id
        })
        .then(() => {
          console.log("unpinMessage Success");
          store.commit("IM/deletePinedMessage", {
            fromId: `${chatType}${fromId}`,
            id
          });
        })
        .catch((e: any) => {
          console.log(e, "unpinMessage failed");
        });
    };

    // 发送转发消息
    const sendCombineMsg = () => {
      let msg = createMsg({
        chatType: chatType,
        // @ts-ignore
        type: MSG_TYPE.combine,
        to: fromId,
        compatibleText: "[不支持消息类型]",
        title: "合并消息标题",
        summary: "合并消息汇总",
        messageList: JSON.parse(
          JSON.stringify(store.state.IM.chat[chatId].messageList)
        )
      }) as any;

      deliverMsg(msg).then((res) => {
        console.log(msg, "combine msg");
        store.commit("IM/pushMessage", {
          fromId: chatId,
          message: msg
        });
        setTimeout(() => {
          scrollToBottom(document.getElementById("msgWrap"));
        }, 200);
        console.log(res, "发送合并消息成功");
      });
    };

    // 发送会话已读回执
    const sendChatReadAck = () => {
      const readAckMsg: any = createMsg({
        type: MSG_TYPE.channel,
        chatType: chatType,
        to: fromId
      });
      deliverMsg(readAckMsg).then((res) => {
        console.log(res, "发送会话已读回执成功");
      });
    };

    // 发送消息已读回执
    const sendMsgReadAck = () => {
      let id =
        store.state.IM.chat[chatId]?.messageList[
          store.state.IM.chat[chatId]?.messageList.length - 1
        ].id;
      const readAckMsg: any = createMsg({
        type: MSG_TYPE.read,
        chatType: chatType as CHAT_TYPE.singleChat | CHAT_TYPE.groupChat,
        id,
        to: fromId
      });

      deliverMsg(readAckMsg).then((res) => {
        console.log(res, "发送消息已读回执成功");
      });
    };

    // 发送群组ack
    const sendGroupMsgAck = () => {
      let id =
        store.state.IM.chat[chatId]?.messageList[
          store.state.IM.chat[chatId]?.messageList.length - 1
        ].id;
      const readAckMsg: any = createMsg({
        type: MSG_TYPE.read,
        chatType: CHAT_TYPE.groupChat,
        id: `${id}`,
        to: fromId,
        ackContent: "group ack content"
      });
      deliverMsg(readAckMsg).then((res) => {
        console.log(res, "发送消息已读回执成功");
      });
    };

    const sendAck = () => {
      if (chatType === CHAT_TYPE.groupChat) {
        sendGroupMsgAck();
      } else {
        sendMsgReadAck();
      }
    };

    const getPinnedMessages = () => {
      store.commit("IM/clearPinnedMessage", {
        fromId: chatId
      });
      conn
        .getServerPinnedMessages({
          conversationId: fromId,
          conversationType: chatType as "groupChat" | "chatRoom",
          pageSize: 100
        })
        .then((res) => {
          res?.data?.list.forEach((item) => {
            item.pinTime = formatTime(
              item.pinTime,
              "YYYY-MM-DD hh:mm:ss"
            ) as unknown as number;
            store.commit("IM/pushPinedMessage", {
              fromId: chatId,
              message: item
            });
          });
          console.log(res, "getServerPinnedMessages");
        })
        .catch((e: any) => {
          console.log(e, "getServerPinnedMessages failed");
        });
    };

    onMounted(() => {
      // 发送会话已读回执
      // sendChatReadAck();
      if (
        !store.state.IM.chat[chatId] ||
        store.state.IM.chat[chatId]?.messageList.length === 0
      ) {
        getHistoryMsg();
      }

      if (chatType === "chatRoom") {
        conn
          .joinChatRoom({
            roomId: fromId
          })
          .then((res) => {
            console.log("加入聊天室成功", res);
          })
          .catch((e) => {
            console.log(e, "加入聊天室失败");
          });
      }
    });

    onUnmounted(() => {
      if (chatType === "chatRoom") {
        conn.leaveChatRoom({
          roomId: fromId
        });
      }
    });

    return {
      fromId: fromId,
      currentUser: conn.user,
      emojiShow,
      emojiLs: emojiLs,
      chatType: chatType,
      chatInfo: store.state.IM.chat,
      pinInfo: store.state.IM.pin,
      pinMessageIdsMap: store.state.IM.pinMessageIdsMap,
      isShowModifyModal,
      showPinedMessageList,
      isShowPinnedMessage,
      pinedMessages,
      modifiedText,
      toChat,
      sendMsg,
      selectEmoji,
      sendEmoji,
      afterReadImg,
      afterReadAttach,
      previewImage,
      previewCombineMsg,
      afterReadVideo,
      onSendClick,
      sendCustomMsg,
      sendCmdMsg,
      revokeMsg,
      modifyMessage,
      pinMessage,
      unpinMessage,
      showModifyModal,
      sendChatReadAck,
      sendMsgReadAck,
      getHistoryMsg,
      sendGroupMsgAck,
      sendCombineMsg,
      getPinnedMessages,
      sendAck
    };
  });
}
